import React from 'react'

const FitLogo = () => {
	return (
		<div className="fit_logo">
			<svg
				width='70'
				id='Layer_1'
				data-name='Layer 1'
				xmlns='http://www.w3.org/2000/svg'
				viewBox='0 0 189.25 109.07'
				>
				<path
					d='M157.86,197.54a4.92,4.92,0,0,0-1.15,0Z'
					transform='translate(-106.83 -138.47)'
					className='cls-1'></path>{' '}
				<path
					d='M276.33,197.49H245.66l-1.35,1.81a146.64,146.64,0,0,1-41.77,37.8A146.93,146.93,0,0,1,166,205.79H154.46a156,156,0,0,0,45.81,40.43l2.26,1.32,2.26-1.32a155.58,155.58,0,0,0,45.35-39.73h26.19a4.5,4.5,0,0,0,0-9Z'
					transform='translate(-106.83 -138.47)'
					className='cls-1'></path>{' '}
				<path
					d='M277.33,231.83a4.5,4.5,0,0,1-4.5-4.5V178.17a4.5,4.5,0,0,1,9,0v49.16A4.49,4.49,0,0,1,277.33,231.83Z'
					transform='translate(-106.83 -138.47)'
					className='cls-1'></path>{' '}
				<path
					d='M291.58,221.17a4.51,4.51,0,0,1-4.5-4.5v-29a4.5,4.5,0,0,1,9,0v29A4.5,4.5,0,0,1,291.58,221.17Z'
					transform='translate(-106.83 -138.47)'
					className='cls-1'></path>{' '}
				<path
					d='M125.58,223.17a4.51,4.51,0,0,1-4.5-4.5V169.5a4.5,4.5,0,0,1,9,0v49.17A4.5,4.5,0,0,1,125.58,223.17Z'
					transform='translate(-106.83 -138.47)'
					className='cls-2'></path>{' '}
				<path
					d='M111.33,212.5a4.5,4.5,0,0,1-4.5-4.5V179a4.5,4.5,0,0,1,9,0v29A4.49,4.49,0,0,1,111.33,212.5Z'
					transform='translate(-106.83 -138.47)'
					className='cls-2'></path>{' '}
				<path
					d='M196.33,210.67a4.51,4.51,0,0,1-4.15-2.78l-10.83-26.06-5.94,12.74a4.51,4.51,0,0,1-4.08,2.6h-7.5l-4.25-9h8.89l9-19.4a4.5,4.5,0,0,1,8.24.17l10.57,25.44,11.36-27.67a4.5,4.5,0,0,1,8.44.29l9.56,29.83h15.25l-7.25,9H222.42a4.51,4.51,0,0,1-4.28-3.08l-6.76-21.38L200.5,207.88a4.54,4.54,0,0,1-4.16,2.79Z'
					transform='translate(-106.83 -138.47)'
					className='cls-1'></path>{' '}
				<path
					d='M252.74,171.25a39.65,39.65,0,0,1-2.68,18.13h9.55a48.72,48.72,0,0,0,2.09-18.95,35.35,35.35,0,0,0-59.16-22.59,35.31,35.31,0,0,0-59.12,22.63,48.46,48.46,0,0,0,1.84,18.09H128.33a4.5,4.5,0,0,0,0,9h30.43l-2.93-6.37a39.65,39.65,0,0,1-3.45-19.9A26.32,26.32,0,0,1,199,157.23l3.48,4.3,3.5-4.29a26.36,26.36,0,0,1,46.72,14Z'
					transform='translate(-106.83 -138.47)'
					className='cls-2'></path>
			</svg>
		</div>
	)
}

export default FitLogo
